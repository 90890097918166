<template>
  <div
    v-if="items.length"
    class="d-flex flex-column gap-8">
    <div class="d-flex align-center justify-space-between gap-8">
      <v-spacer />
      <SearchFilterVerifiedOnly />
      <SearchFilterWorkspaceOnly />
    </div>
    <slot>
      <div
        v-for="item in items"
        :key="item.title"
        :data-test="`${item.dataTest}_section`">
        <GroupItems
          :is-show-action-icon="isShowActionIcon"
          :item="item"
          v-on="$listeners" />
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'CollectionsSearchGroups',
  components: {
    GroupItems: () => import('./CollectionsSearchGroupItems'),
    SearchFilterWorkspaceOnly: () => import('@/components/SearchFilter/SearchFilterWorkspaceOnly'),
    SearchFilterVerifiedOnly: () => import('@/components/SearchFilter/SearchFilterVerifiedOnly'),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isShowActionIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

